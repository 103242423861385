import React, { useState, useEffect, useRef } from 'react';
import styles from './RangeSlider.module.css';
import { fromEvent } from 'rxjs';
import { mergeMap, takeUntil, filter, tap } from 'rxjs/operators';

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return width;
}

function useMousePos(targetId, audioSrc) {
  if (typeof document === 'undefined') return;
  
  const targetElem = document.getElementById(targetId);
  const [mousePos, setMousePos] = useState(0);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (targetElem) {
      const boundinClientRect = targetElem.getBoundingClientRect();

      const handleEvent = e => setMousePos(e.clientX - boundinClientRect.left);

      const mouseDown$ = fromEvent(targetElem, 'pointerdown').pipe(
        tap(handleEvent)
      );
      const mouseMove$ = fromEvent(document, 'pointermove');
      const mouseUp$ = fromEvent(document, 'pointerup');
      const pointerDrag$ = mouseDown$.pipe(
        mergeMap(() => mouseMove$.pipe(takeUntil(mouseUp$)))
      );

      const subscription = pointerDrag$
        .pipe(
          filter(e => {
            return (
              e.clientX - boundinClientRect.left < boundinClientRect.width &&
              e.clientX > boundinClientRect.left
            );
          })
        )
        .subscribe(handleEvent);
      return () => subscription.unsubscribe();
    }
  }, [targetElem, windowWidth]);

  // Reset mouse position
  useEffect(() => setMousePos(0), [audioSrc]);

  return mousePos;
}

export default function RangeSlider(props) {
  const { value, min = 0, max = 100, onChange, audioSrc } = props;

  const containerRef = useRef(null);
  const mousePosition = useMousePos('slider-container', audioSrc);
  const [thumbPosition, setThumbPosition] = useState(mousePosition);

  useEffect(() => {
    if (!max) return;
    let thumbPos = Math.min(value / max, 1);
    setThumbPosition(
      containerRef.current.getBoundingClientRect().width * thumbPos
    );
  }, [value, max]);

  useEffect(() => {
    if (!onChange) return;
    onChange(
      (mousePosition / containerRef.current.getBoundingClientRect().width) * max
    );
  }, [mousePosition, onChange, max]);

  return (
    <div
      id="slider-container"
      className={styles.sliderContainer}
      ref={containerRef}
    >
      <div className={styles.sliderFill} style={{ width: thumbPosition + 1 }} />
      <div
        className={styles.sliderThumb}
        style={{ left: thumbPosition - 7.5 }}
      />
    </div>
  );
}
