import React from 'react';
import TimeDisplay from './TimeDisplay';
import RangeSlider from './RangeSlider';
import styles from './AudioControls.module.css';
import IconButton from '@material-ui/core/IconButton';
import { PlayArrow, Pause, SkipPrevious } from '@material-ui/icons';

export class AudioControls extends React.Component {
  state = {
    audioCurrentTime: 0,
    audioDuration: 0
  };

  constructor(props) {
    super(props);
    this.audio = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.audioPlaying !== prevProps.audioPlaying) {
      if (this.props.audioPlaying) {
        this.audio.current.play();
      } else {
        this.audio.current.pause();
      }
    }
  }

  changePosition = value => {
    this.audio.current.currentTime = value;
  };

  setAudioDuration = event => {
    this.setState({ audioDuration: Math.floor(event.target.duration) });
  };

  setCurrentTime = event => {
    this.setState({
      audioCurrentTime: Math.floor(event.target.currentTime)
    });
  };

  handlePlayClick = () => {
    if (this.audio.current.paused) {
      this.audio.current.play();
    } else {
      this.audio.current.pause();
    }
    this.props.onPlayAudio();
  };

  handleTrackChange = event => {
    this.audio.current.play();
  };

  displayTime(time) {
    return {
      minutes: Math.floor(time / 60),
      seconds: time % 60
    };
  }

  render() {
    const { audioSrc, trackName, handleAudioEnded } = this.props;
    const timeCurrent = this.displayTime(this.state.audioCurrentTime);
    const timeTotal = this.displayTime(this.state.audioDuration);
    return (
      <div className={styles.container}>
        <p className={styles.trackTitle}>{trackName}</p>
        <div className={styles.controls}>
          <audio
            ref={this.audio}
            src={audioSrc}
            onTimeUpdate={this.setCurrentTime}
            onLoadedMetadata={this.setAudioDuration}
            onEnded={handleAudioEnded}
            onEmptied={this.handleTrackChange}
          ></audio>

          <IconButton onClick={() => this.changePosition(0)} size="small">
            <SkipPrevious />
          </IconButton>

          <IconButton onClick={this.handlePlayClick} size="small">
            {this.props.audioPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>

          <TimeDisplay timeCurrent={timeCurrent} timeTotal={timeTotal} />

          <RangeSlider
            value={this.state.audioCurrentTime}
            min={0}
            max={this.state.audioDuration}
            thumbWidth={15}
            onChange={this.changePosition}
            audioSrc={audioSrc}
          />
        </div>
      </div>
    );
  }
}
