import React, { useState, useLayoutEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Cancel from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AudioPlayer from '../components/AudioPlayer';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/seo';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px',
    borderRadius: 0,
    marginTop: '2em',
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 350px))',
    gridGap: '1.5em',
    justifyContent: 'center',
    paddingBottom: '20px',
    maxWidth: '1000px',
    margin: 'auto',
  },
  albumTitle: {
    gridColumn: '1 / -1',
  },
  description: {
    margin: '10px',
  },
  image: (props) => ({
    border: '1px solid #ccc',
    cursor: props.smScreen ? 'pointer' : 'default',
  }),
  fullImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    maxWidth: '80vh',
    margin: 'auto',
    padding: '0.7em',
    paddingTop: '2em',
    opacity: 0,
    transition: 'opacity 0.4s ease-out',
  },
  fullImageBtn: {
    position: 'absolute',
    top: '10px',
    right: '-10px',
    zIndex: 2,
    color: 'black',
  },
  author: {
    fontSize: '0.9em',
  },
}));

function FullImage({ imgData, onClick }) {
  const { fullImage, fullImageBtn } = useStyles();
  const containerRef = useRef();

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      containerRef.current.style.opacity = 1;
    }, 10);

    return function () {
      clearTimeout(timeout);
    };
  });

  return (
    <div ref={containerRef} className={fullImage} onClick={onClick}>
      <Fab className={fullImageBtn}>
        <Cancel />
      </Fab>
      <Img
        fluid={imgData}
        alt="Full album cover image"
        style={{ boxShadow: '10px 10px 10px rgba(50, 50, 50, 0.3)' }}
      />
    </div>
  );
}

export default function AlbumDetail({ data: { album, googleStorage } }) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const classes = useStyles({ smScreen });

  const [showFullImg, setShowFullImg] = useState(false);

  function handleImgClick() {
    if (!smScreen) return;
    setShowFullImg((state) => !state);
  }

  return (
    <Layout>
      <SEO
        title={'Music from album ' + album.name}
        description={album.description}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <section className={classes.albumTitle}>
            <h2>{album.name}</h2>
            <p>{album.release}</p>
          </section>

          <section>
            <div
              onClick={handleImgClick}
              style={{ opacity: showFullImg && smScreen ? 0.3 : 1 }}
            >
              <Img
                fluid={googleStorage.localFile.childImageSharp.fluid}
                alt="Album cover image"
                className={classes.image}
              />
            </div>
            <p className={classes.description}>{album.description}</p>
          </section>

          <section>
            {album.tracks.length !== 0 && <AudioPlayer tracks={album.tracks} />}
          </section>
        </div>
      </div>
      {showFullImg && smScreen && (
        <FullImage
          imgData={googleStorage.localFile.childImageSharp.fluid}
          onClick={handleImgClick}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'white',
          opacity: 0.8,
          zIndex: -1,
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query($name: String!, $fileName: String!) {
    album(name: { eq: $name }) {
      name
      release
      imgSrc
      description
      tracks {
        audioSrc
        fileName
        id
        name
      }
    }
    googleStorage(name: { eq: $fileName }) {
      localFile {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
