import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { PlayArrow, Pause } from '@material-ui/icons';
import { AudioControls } from './AudioControls/AudioControls';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  track: {
    display: 'flex',
    alignItems: 'center',
    visibility: 'visible',
    minHeight: '30px',
  },
});

function TrackName({ name, index, isSelected }) {
  const trackName = `${index + 1}. ${name}`;
  return <span>{isSelected ? <strong>{trackName}</strong> : trackName}</span>;
}

export default function AudioPlayer({ tracks }) {
  const classes = useStyles();

  const [selectedTrack, setSelectedTrack] = useState(
    tracks.find((track) => track.audioSrc)
  );
  const [audioPlaying, setAudioPlaying] = useState(false);

  function selectTrack(track) {
    if (track.name !== selectedTrack.name) {
      setSelectedTrack(track);
      setAudioPlaying(true);
    } else {
      setAudioPlaying((playing) => !playing);
    }
  }

  const playAudio = () => setAudioPlaying((playing) => !playing);

  function setNextTrack() {
    const currentIndex = tracks.findIndex(
      (track) => track.name === selectedTrack.name
    );
    if (currentIndex < tracks.length - 1) {
      setSelectedTrack(tracks[currentIndex + 1]);
    } else {
      setSelectedTrack(tracks[0]);
    }
  }

  return (
    <>
      {selectedTrack && selectedTrack.audioSrc && (
        <AudioControls
          audioSrc={selectedTrack.audioSrc}
          trackName={selectedTrack.name}
          audioPlaying={audioPlaying}
          onPlayAudio={playAudio}
          handleAudioEnded={setNextTrack}
        />
      )}

      <ul>
        {tracks.map((track, index) => (
          <li key={track.name} className={classes.track}>
            {track.audioSrc && (
              <IconButton onClick={() => selectTrack(track)} size="small">
                {track.name === selectedTrack.name && audioPlaying ? (
                  <Pause />
                ) : (
                  <PlayArrow />
                )}
              </IconButton>
            )}
            <TrackName
              name={track.name}
              index={index}
              isSelected={track.audioSrc && track.name === selectedTrack.name}
            />
          </li>
        ))}
      </ul>
    </>
  );
}
