import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    padding: '0 5px',
    fontSize: '0.85em',
    display: 'inline-flex',
    justifyContent: 'space-between',
    marginRight: '5px',
    pointerEvents: 'none',
    width: '80px',
  },
  currentTime: {
    flex: '1 0 auto',
  },
  divider: {
    marginRight: '2px',
  },
});

export default function TimeDisplay({ timeCurrent, timeTotal }) {
  const classes = useStyles();

  function getSeconds(time) {
    return time.seconds < 10 ? '0' + time.seconds : time.seconds;
  }

  return (
    <div className={classes.container}>
      <span className={classes.currentTime}>
        {timeCurrent.minutes}:{getSeconds(timeCurrent)}{' '}
      </span>
      <span className={classes.divider}>/</span>
      <span>
        {' '}
        {timeTotal.minutes}:{getSeconds(timeTotal)}
      </span>
    </div>
  );
}
